
import { Options, Vue } from 'vue-class-component';
import {
    apiReportGetListTotalNum,
    apiReportGetList
} from '../services/tpsApi/tpsApiReport';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import {
    IReportGetListTotalNumRequestParams,
    IReportGetListRequestParams
} from '../services/tpsApi/models/IReport';
import ReportRouteSearchMenu from '@/components/ReportRouteSearchMenu.vue';
import RouteRecord from '@/components/RouteRecord.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';
import Alert from '@/components/Alert.vue';
import RouteRecordGoogle from '@/components/RouteRecordGoogle.vue';
import RouteRecordRaw from '@/components/RouteRecordRaw.vue';

@Options({
    components: {
        ReportRouteSearchMenu,
        Table,
        Pagination,
        RouteRecord,
        RouteRecordGoogle,
        RouteRecordRaw
    },
})
export default class ReportRoute extends Vue {
    $refs!: {
        form: RouteRecord,
        formGoogle: RouteRecordGoogle,
        formRaw: RouteRecordRaw,
        alert: Alert
    }
    TABLE_HEAD: {} = {
        route_id: "軌跡代碼",
        "品牌名稱": "品牌名稱",
        "車號": "車號",
        "駕駛員帳號": "駕駛員帳號",
        "登入日期": "登入日期",
        "登入時間": "登入時間",
        "登入經度": "登入經度",
        "登入緯度": "登入緯度",
        "登出日期": "登出日期",
        "登出時間": "登出時間",
        "登出經度": "登出經度",
        "登出緯度": "登出緯度",
        "行駛時間": "行駛時間",
        "行駛距離": "行駛距離 KM (Map8)",
        // distance_g: "行駛距離(Google)",
        distance_raw: "行駛距離 KM (RAW)",
    };

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;

    reportList: [] | Blob = [];
    REPORT = "6"; //report=6，表示產生第六種業者報表，行駛軌跡明細表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};
    formVisible: boolean = false;
    formGoogleVisible: boolean = false;
    formRawVisible: boolean = false;

    mounted() {
        this.refreshTabel({ report: this.REPORT, limit: this.currentLimit, page: this.currentPage });
        this.initPagination();
    }

    async initPagination() {
        let query = Object.assign({ report: this.REPORT }, this.searchQuery);
        this.pageTotalNum = await apiReportGetListTotalNum(query);
        console.log(this.pageTotalNum);
    }

    async refreshTabel(pData: IReportGetListRequestParams) {
        if (pData && pData.limit) this.currentLimit = pData.limit;
        if (pData && pData.page) this.currentPage = pData.page;
        if (pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        this.reportList = await apiReportGetList(pData);
    }

    async showForm(pData: { route: JSON }) {
        console.log(pData);
        
        if (pData.route) {
            this.$refs.form.initForm(pData.route);
            this.formVisible = true;
        }
    }

    async showRouteGoogle(pData: { route_g: JSON }) {
        console.log("Clicked")
        console.log(pData);
        
        if (pData.route_g) {
            this.$refs.formGoogle.initFormGoogle(pData.route_g);
            this.formGoogleVisible = true;
        }
    }

    async showRouteRaw(pData: { route_raw: JSON }) {
        console.log("Clicked")
        console.log(pData);
        
        if (pData.route_raw) {
            this.$refs.formRaw.initFormRaw(pData.route_raw);
            this.formRawVisible = true;
        }
    }

    hideForm(pData: { actionType: string, alertType: string, msg: string }) {
        this.formVisible = false;
        //this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({ report: this.REPORT, limit: this.currentLimit, page: this.currentPage });
        if (pData.actionType == "create") this.initPagination();
    }

    hideFormGoogle(pData: { actionType: string, alertType: string, msg: string }) {
        this.formGoogleVisible = false;
        //this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({report: this.REPORT,limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    hideFormRaw(pData: { actionType: string, alertType: string, msg: string }) {
        this.formRawVisible = false;
        //this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({report: this.REPORT,limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async download(pData: { output: string }) {
        const res = await apiReportGetList(Object.assign({
            report: this.REPORT,
            output: pData.output
        },
            this.searchQuery));
        exportFile({ fileName: "行駛軌跡明細表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({ report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE });
    }
    doAlert(pData: { alertType: string, msg: string }) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
