
    import { Options, Vue } from 'vue-class-component';
    import { Prop, Emit } from "vue-property-decorator";
    import WarningModal from '@/components/WarningModal.vue';
    import "leaflet/dist/leaflet.css";
    import L from "leaflet";
    
    @Options({
      components: {
          WarningModal
      },
      emits: [
          "hide-form"
      ]
    })
    export default class RouteRecord extends Vue {
         $refs!: {
            warning: WarningModal
        }
    
        @Prop({ required: true }) showForm: boolean = false;
    
        
        
        actionType: string = "";
        routeID: number = -1;
        route
        map
        path
      
      async beforeMount() {
        // HERE is where to load Leaflet components!
        const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");
    
      
      }
     
      
    
        async initForm(json) {
            
            //console.log(this.map.getSize())
            console.log(json)
            if(json){
                this.route = JSON.parse(json)
                console.log(this.route[0])
                this.map = L.map("mapContainer").setView(this.route[0], 16);
                //console.log(this.map.getSize())
                // this.map.invalidateSize();
                
                //console.log(this.map.getSize())
                await L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                }).addTo(this.map);
                await L.polyline(this.route,{color: 'green'}).addTo(this.map);
                
                
            }
            //console.log(this.route[0])
           
        }
       async updated(){
           this.map.invalidateSize();
       }
      async mounted(){
        
      }
    
        handleCancel() {
            this.hideForm(false);
            if (this.map != undefined) { this.map.remove(); }
        }
    
        @Emit("hide-form")
        hideForm(pSuccess) {
            if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
        }
    }
    